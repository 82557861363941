
































































































































import { Vue, Component } from 'vue-property-decorator';
// import moment from "moment";
import { WorkingPeriodResource } from '../classes/WorkingPeriod.resource';
import { MeetingStore } from '@/store';
import { downloadMeetConfigAsJson } from '../helper/index';
@Component({
	components: {
		MeetingsTable: async () => import('../components/MeetingsTable.vue'),
		NextMeetCard: async () => import('../components/NextMeetCard.vue'),
		BaseToolTip: () => import('../components/base/BaseToolTip.vue')
	}
})
export default class Overview extends Vue {
	now: Date = new Date();
	exportName = 'MeetingTimerConfig';
	showSaveAlert = false;

	//Meet

	async mounted(): Promise<void> {
		MeetingStore.workingPeriods.length > 0 ? (this.showSaveAlert = true) : (this.showSaveAlert = false);
	}

	async downloadObjectAsJson(): Promise<void> {
		downloadMeetConfigAsJson(this.workingPeriods, MeetingStore.globalId, MeetingStore.clientId);
	}

	async deleteWorkingPeriod(workingPeriod: WorkingPeriodResource): Promise<void> {
		MeetingStore.deleteWorkingPeriod(workingPeriod);
	}

	//Getters

	get futureMeets(): WorkingPeriodResource[] | null {
		if (this.workingPeriods.length > 0) {
			const futureMeets: WorkingPeriodResource[] = [];
			const now = new Date();
			this.workingPeriods.forEach(function (meet) {
				if (new Date(meet.meetDay).valueOf() > now.valueOf() && meet.status === 'not-started') {
					futureMeets.push(meet);
				}
			});
			return futureMeets;
		} else {
			return null;
		}
	}

	get todayMeets(): WorkingPeriodResource[] | null {
		if (this.workingPeriods.length > 0) {
			const todayMeets: WorkingPeriodResource[] = [];
			const now = new Date();
			this.workingPeriods.forEach(function (meet) {
				if (new Date(now).toLocaleDateString('de-DE') === new Date(meet.meetDay).toLocaleDateString('de-DE') && meet.status === 'not-started') {
					todayMeets.push(meet);
				}
			});
			return todayMeets;
		} else {
			return null;
		}
	}

	get nextSmallestDayMeets(): WorkingPeriodResource[] | null {
		if (this.workingPeriods.length > 0) {
			const nextSmallestDayMeets: WorkingPeriodResource[] = [];
			const smallestMinDay = String(this.smallestMinDate);
			this.workingPeriods.forEach(function (meet) {
				if (new Date(smallestMinDay).toLocaleDateString('de-DE') === new Date(meet.meetDay).toLocaleDateString('de-DE') && meet.status === 'not-started') {
					nextSmallestDayMeets.push(meet);
				}
			});
			return nextSmallestDayMeets;
		} else {
			return null;
		}
	}

	get smallestMinDate(): Date | null {
		if (this.futureMeets && this.futureMeets.length > 1) {
			//find smallest date in future meets
			let smallestMinDay = this.futureMeets[0].meetDay;
			// let target: WorkingPeriodResource = this.futureMeets[0];
			this.futureMeets.forEach(function (meet) {
				if (new Date(meet.meetDay).valueOf() < new Date(smallestMinDay).valueOf()) {
					smallestMinDay = meet.meetDay;
				}
			});
			return smallestMinDay;
		}
		return null;
	}

	get nextDueWorkingPeriod(): WorkingPeriodResource | null {
		if (this.todayMeets && this.todayMeets.length > 0) {
			let minTime = this.todayMeets[0].meetTime;
			let target: WorkingPeriodResource = this.todayMeets[0];
			this.todayMeets.forEach(function (meet) {
				if (meet.meetTime < minTime) {
					minTime = meet.meetTime;
					target = meet;
				}
			});
			return target;
		}
		if (this.futureMeets && this.futureMeets.length > 0) {
			if (this.futureMeets.length === 1) {
				return this.futureMeets[0];
			} else {
				//find smallest date in future meets
				if (this.nextSmallestDayMeets) {
					let target: WorkingPeriodResource = this.nextSmallestDayMeets[0];
					let nestSmallestMinTime = this.nextSmallestDayMeets[0].meetTime;
					this.nextSmallestDayMeets.forEach(function (meet) {
						if (meet.meetTime < nestSmallestMinTime && meet.status === 'not-started') {
							nestSmallestMinTime = meet.meetTime;
							target = Object.assign({}, meet);
						}
					});
					return target;
				}
			}
		}
		return null;
	}

	get workingPeriods(): WorkingPeriodResource[] {
		return MeetingStore.workingPeriods;
	}
}
